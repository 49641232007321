import { render, staticRenderFns } from "./DiagDetails.vue?vue&type=template&id=041fd346&scoped=true&"
import script from "./DiagDetails.vue?vue&type=script&lang=js&"
export * from "./DiagDetails.vue?vue&type=script&lang=js&"
import style0 from "./DiagDetails.vue?vue&type=style&index=0&id=041fd346&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041fd346",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
