<template>
  <div class="lxp_news clear min_height">
    <div class="lxp_news_margin clear">
      <div class="lxp_news_fenlei">
        <div v-for="(item,index) of categoryList" :key="`categoryList + ${index}`" class="lxp_news_fenlei_vfor" @click="toAnothor(item.id)">
          <div class="lxp_news_fenlei_title">{{item.name}}</div>
          <template v-if="item.id==1 && categoryIdOne">
          <div class="lxp_news_fenlei_slider"></div>
          </template>
          <template v-if="item.id==2 && categoryIdTwo">
            <div class="lxp_news_fenlei_slider"></div>
          </template>
        </div>
      </div>
      <div class="lxp_news_float_mar clear">
        <div
          class="lxp_news_float"
          v-for="(item, index) of news"
          :key="`news + ${index}`"
          @click="toNewDetails(item.id)"
        >
          <div style="width: 96%; margin: 6% 2%">
            <div class="lxp_news_float_1">
              <v-img
                :src="item.mpic"
                contain
                max-height="123"
                max-width="315"
                style="margin:auto"
              ></v-img>
            </div>
            <div class="lxp_news_float_2">
              <span>{{ item.title }}</span>
            </div>
            <div class="lxp_news_float_3">
              <span>{{ item.updated_at }}</span>
            </div>
            <div class="lxp_news_float_4" >
              <span>查看详情</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lxp_news_float_page">
        <v-pagination
          v-model="page"
          :length="pageLength"
          :total-visible="7"
          @input="goToPage"
          prev-icon="上一页"
      next-icon="下一页"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsLists,getNewsCategory} from "@/api/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      pageLength: 1,
      news: [],
      page: 1,
      categoryList:[],
      categoryIdOne:true,
      categoryIdTwo:false,
      ccid:1,
    };
  },
  created() {
    this.loadNewsPage();
    this.loadNewsCategory();
  },
  methods: {
    /* 分类导航渲染思路:
      1.用v-show判断isShow==item.id,默认isShow的值为1，即默认显示第一个分类，以及分类下的新闻；
      2.在mounted里判断，先获取this.$route.params.id，把这获取到的id传给一个变量，再调用接口，渲染数据
      3.写一个切换方法，传item.id的值，去调用接口，渲染数据
      4.在watch监听路由，重新执行一遍mounted里的逻辑
      5.在路由那里带个参数/:id
      6.在头部那里导航进来，携带一个默认id

    */


   //分类跳转
   toAnothor(_id){
      this.ccid = _id;
     if(_id==1){
       this.categoryIdOne = true;
       this.categoryIdTwo = false;
     }else if(_id==2){
       this.categoryIdOne = false;
       this.categoryIdTwo = true;
     }
       this.loadNewsPage();
   },
    //跳转相应页面
    goToPage(_n) {
      this.news = [];
      this.page = _n;
     setTimeout(()=>{
       this.loadNewsPage();
     },500)
        
  
      window.scrollTo(0, 0);
    },
    toNewDetails(_id) {
      this.$router.push("/newsdetails/" + _id);
    },

    //获取新闻分类页数据
    loadNewsCategory(){
      return getNewsCategory()
      .then((res) => {
        if (res && res.code == 0 && res.data) {
          this.categoryList = res.data;
        }
      })
    },

    //获取新闻列表页数据
    loadNewsPage() {
      console.log(this.page);
      return getNewsLists({ cid:this.ccid,page: this.page, size: 10 }).then((res) => {
        if (res && res.code == 0 && res.data) {
          this.news = res.data.data || [];
          this.pageLength = res.data.last_page;
          // this.page = res.data.current_page;
        }
        console.log(this.pageLength, res,'=====cid',this.ccid, this.news, this.pageLength);
      });
    },
    loadNew() {
      console.log(this.page);
    },
  },
  computed: {
    // img_Path(){
    //   return this.$store.state.imgPath;
    // }
    ...mapState({
      img_path: (state) => state.imgPath + "news/",
    }),
  },
};
</script>

<style lang="scss" scoped>
.lxp_news {
  width: 100%;
  background: #ffffff;
  .lxp_news_margin {
    width: 65%;
    margin: 3% auto 6%;
    .lxp_news_fenlei {
      width: 70%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 3%;
      .lxp_news_fenlei_vfor {
        text-align: center;
        margin-right: 1%;
        width: 20%;
        cursor: pointer;
        .lxp_news_fenlei_title {
          font-size: 20px;
          font-family: Arial-Bold, Arial;
          font-weight: bold;
          color: #363636;
          padding: 0 2% 2%;
        }
        .lxp_news_fenlei_slider {
              margin: auto;
          width: 133px;
          height: 4px;
          background: #2eb486;
          border-radius: 30px 30px 30px 30px;
          opacity: 0.8;
        }
      }
    }

    .lxp_news_float_mar {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      .lxp_news_float {
        cursor: pointer;
        width: 30%;
        border: 1px solid rgba(112, 112, 112, 0.39);
        // float: left;
        margin-left: 2.5%;
        margin-bottom: 2.5%;

        .lxp_news_float_1 {
          margin: auto;
          width: 93%;
          height: 123px;
        }
        .lxp_news_float_2 {
          margin: 3% auto;
          width: 90%;
          height: 64px;
          line-height: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          span {
            font-size: 20px;
            font-family: Arial;
            font-weight: bold;
            color: #363636;
            opacity: 1;
          }
        }
        .lxp_news_float_3 {
          margin: auto;
          width: 90%;
          span {
            font-size: 20px;
            font-family: Arial;
            font-weight: 400;
            color: #808080;
            opacity: 1;
          }
        }
        .lxp_news_float_4 {
          margin: 5% auto 0;
          width: 50%;
          height: 43px;
          background: #2eb486;
          opacity: 1;
          position: relative;
          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
    .lxp_news_float_page {
      margin: auto;
      text-align: center;
    }
  }
}
</style>