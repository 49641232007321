<template>
  <div class="lxp_support min_height">
    <div class="lxp_support_margin">
      <div v-if="items.length > 0">
        <div style="width: 96%">
          <v-tabs
            v-model="tab"
            align-with-title
            :show-arrows="false"
            :grow="true"
            :centered="true"
          >
            <v-tabs-slider
              color="#2EB486"
              style="margin-left: 0 !important"
            ></v-tabs-slider>
            <v-tab
              v-for="(item, index) of items"
              :key="`items + ${index}`"
              class="lxp_support_margin_title"
              @click="toCid(item.id)"
              :href="`#${getTabKey(item.id)}`"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </div>
        <div class="lxp_support_margin_top">
          <v-tabs-items v-model="tab">
            <!--常见问题-->
            <v-tab-item value="a">
              <template>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(item1, index1) of qw1"
                    :key="`qw + ${index1}`"
                    class="lxp_support_p"
                  >
                    <v-expansion-panel-header>
                      {{ item1.title }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span v-html="item1.content"></span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- <div class="lxp_support_float_page">
                  <v-pagination
                    v-model="page"
                    :length="pageLength"
                    :total-visible="7"
                    @input="goToPage"
                  ></v-pagination>
                </div> -->
              </template>
            </v-tab-item>

            <!--产品说明书-->
            <v-tab-item value="b">
              <template>
                <div class="lxp_support_two_details_illustrate">
                  <div class="lxp_support_two_details_illustrate_flex">
                    <div
                      class="lxp_support_two_details_illustrate_flex_1"
                      v-for="(item1, index1) of qw"
                      :key="`qwproduct + ${index1}`"
                    >
                      <div
                        class="lxp_support_two_details_illustrate_flex_1_img"
                      >
                        <v-img
                          contain
                          :src="item1.mpic"
                          :lazy-src="item1.mpic"
                          max-width="120px"
                        ></v-img>
                      </div>
                      <div
                        class="
                          lxp_support_two_details_illustrate_flex_1_content
                        "
                      >
                        <div
                          class="
                            lxp_support_two_details_illustrate_flex_1_content_title
                          "
                        >
                          <span>{{ item1.title }} </span>
                        </div>
                        <div
                          class="
                            lxp_support_two_details_illustrate_flex_1_content_des
                          "
                        >
                          <span>{{ item1.subject }}</span>
                        </div>
                        <div
                          class="
                            lxp_support_two_details_illustrate_flex_1_content_btn
                          "
                          @click="toDownLoad(item1.download)"
                        >
                          <span>点击下载</span>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div class="lxp_support_float_page">
                  <v-pagination
                    v-model="page"
                    :length="pageLength"
                    :total-visible="7"
                    @input="goToPage"
                    prev-icon="上一页"
                    next-icon="下一页"
                  ></v-pagination>
                </div>
              </template>
            </v-tab-item>

            <!--诊断知识-->
            <v-tab-item value="c">
              <template>
                <div
                  class="lxp_support_diag"
                  v-for="(i, j) of qw"
                  :key="`qw3 + ${j}`"
                >
                  <div class="lxp_support_diag_img">
                    <v-img :src="i.mpic"></v-img>
                  </div>
                  <div class="lxp_support_diag_content">
                    <div class="lxp_support_diag_content_mar">
                      <div class="lxp_support_diag_content_title">
                        <span>{{ i.title }}</span>
                      </div>
                      <div class="lxp_support_diag_content_des">
                        <span>{{ i.subject }}</span>
                      </div>
                      <!-- <div v-show="i.id == isId">
                        <span v-html="i.content"></span>
                      </div> -->
                      <div
                        class="lxp_support_diag_content_btn"
                        @click="toDetails(i.id)"
                      >
                        <span>查看详情</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="lxp_support_float_page">
                  <v-pagination
                    v-model="page"
                    :length="pageLength"
                    :total-visible="7"
                    @input="goToPage"
                    prev-icon="上一页"
                    next-icon="下一页"
                  ></v-pagination>
                </div>
              </template>
            </v-tab-item>

            <!--升级中心-->
            <v-tab-item value="d">
              <template>
                <div class="lxp_support_upgrade">
                  <div class="lxp_support_two_details_upgrade_flex">
                    <div
                      class="lxp_support_two_details_upgrade_flex_1"
                      v-for="(i, j) of qw"
                      :key="`qwUp + ${j}`"
                    >
                      <div class="lxp_support_two_details_upgrade_flex_1_img">
                        <v-img
                          contain
                          :src="i.mpic"
                          :lazy-src="i.mpic"
                          max-width="94px"
                        ></v-img>
                      </div>
                      <div
                        class="lxp_support_two_details_upgrade_flex_1_content"
                      >
                        <div
                          class="
                            lxp_support_two_details_upgrade_flex_1_content_title
                          "
                        >
                          <span>{{ i.title }}</span>
                        </div>

                        <div
                          class="
                            lxp_support_two_details_upgrade_flex_1_content_btn
                          "
                          @click="toUp(i.download)"
                        >
                          <span>点击下载</span>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>

                <div class="lxp_support_float_page">
                  <v-pagination
                    v-model="page"
                    :length="pageLength"
                    :total-visible="7"
                    @input="goToPage"
                    prev-icon="上一页"
                    next-icon="下一页"
                  ></v-pagination>
                </div>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>
         
<script>
import { mapState, mapMutations } from "vuex";
import { getSupport, getSupportList, getSupportDetail } from "@/api/api";
export default {
  props: ["id"],
  data() {
    return {
      isId: null,
      page: 1,
      pageLength: 0,
      qw: [],
      qw1: [],
      tab: "a",
      items: [],
      cid: null,
    };
  },
  created() {
    window.scrollTo(0, 0);
    if (this.$route.query) {
      this.tab = this.getTabKey(this.$route.params.id);
      this.cid = this.$route.params.id;
      this.$nextTick(() => {
        console.log(this.tab, this.cid);
        this.getSupportPage();
        this.getSupportListPage();
      });
    }
  },
  mounted() {
    // if (this.$route.query && this.$route.query.t) {
    //   this.active = this.$route.query.t || ''
    // }
  },
  updated() {
    // if (this.tab == 0) {
    //   this.getSupportListPage();
    // }
  },
  methods: {
    //升级中心下载
    toUp(_url) {
      window.open(_url);
    },
    //说明书点击下载
    toDownLoad(_url) {
      window.open(_url);
    },
    getTabKey(_id) {
      let _txt = "";
      _id == 1 && (_txt = "a");
      _id == 2 && (_txt = "b");
      _id == 3 && (_txt = "c");
      _id == 4 && (_txt = "d");
      return _txt;
    },
    //分页
    goToPage(_n) {
      this.qw = [];
      getSupportList({ cid: this.cid, page: _n, size: 10 }).then((res) => {
        if (res && res.code == 0 && res.data) {
          this.qw = res.data.data;
          console.log(this.qw);
          this.page = res.data.current_page;
        }
      });
    },
    //获取支持页数据
    getSupportPage() {
      return getSupport().then((res) => {
        if (res && res.data && res.code == 0) {
          this.items = res.data;
        }
        console.log(res);
      });
    },
    //获取分类数据
    getSupportListPage() {
      if (this.cid == 1) {
        return getSupportList({ cid: this.cid, size: 14, page: 1 }).then(
          (res) => {
            this.qw1 = res.data.data;
          }
        );
      } else {
        return getSupportList({ cid: this.cid, size: 10, page: 1 }).then(
          (res) => {
            if (res && res.data && res.code == 0) {
              let _num = res.data.total % 10;
              let _len;
              if (_num == 0) {
                _len = res.data.data.length / 10;
                this.pageLength = _len;
              } else {
                _len = Math.floor(res.data.data.length / 10) + 1;
                console;
                this.pageLength = _len;
              }

              let _arr = [];
              this.qw = res.data.data;
              for (let i = 0; i < res.data.data.length; i++) {
                console.log(res.data.data[i]);
                _arr.push(res.data.data[i]);
              }
            }
          }
        );
      }
    },

    // getQuestionList(){
    //   return getSupportList({ cid: this.cid, size:14, page: 1 }).then(
    //     (res) => {
    //       this.qw1 = res.data.data;
    //     }
    //   )
    // },

    toCid(_id) {
      console.log(_id);
      window.scrollTo(0, 0);
      this.cid = _id;
      this.qw = [];
      this.qw1 = [];
      if (_id == 1) {
        getSupportList({ cid: _id, size: 14, page: 1 }).then((res) => {
          if (res && res.data && res.code == 0) {
            this.qw1 = res.data.data;
          }
        });
      } else {
        getSupportList({ cid: _id, size: 10, page: 1 }).then((res) => {
          if (res && res.data && res.code == 0) {
            this.qw = res.data.data;
            let _num = res.data.total % 10;
            let _len;
            if (_num == 0) {
              _len = res.data.data.length / 10;
              this.pageLength = _len;
            } else {
              _len = Math.floor(res.data.data.length / 10) + 1;
              this.pageLength = _len;
            }
            setTimeout(() => {
              console.log("===666", res.data);
            }, 3000);
          }
        });
        console.log(this.qw);
      }
    },
    toDetails(_id) {
      this.isId = _id;
      this.$router.push("/diagdetails/" + _id);
    },
    // onClick() {
    //   console.log("======biu biu biu");
    //   console.log(this.tab);
    // },
  },
  watch: {
    $route() {
      // location.reload();
      this.tab = this.getTabKey(this.$route.params.id);
      this.cid = this.$route.params.id;
      this.$nextTick(() => {
        console.log(this.tab, this.cid);
        this.getSupportPage();
        this.getSupportListPage();
      });
    },
  },
  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
      totalData: (state) => state.totalData,
      pageSize: (state) => state.pageSize,
    }),
  },
};
</script>

<style lang="scss" scoped>
.lxp_support {
  width: 100%;
  background: #ffffff;
  .llll {
    width: 100px;
    height: 100px;
    background: red;
  }
  .lxp_support_margin {
    width: 65%;
    margin: 2% auto 6%;
    // border: 1px solid;
    .lxp_support_margin_top {
      margin-top: 50px;
      .lxp_support_p {
        p {
          margin-bottom: 0 !important;
        }
      }
    }
    .lxp_support_diag {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 40px 0;
      border-bottom: 1px solid #d6d6d6;
      .lxp_support_diag_img {
        width: 33.5%;
      }
      .lxp_support_diag_content {
        width: 60%;
        .lxp_support_diag_content_mar {
          .lxp_support_diag_content_title {
            padding-top: 8px;
            span {
              font-size: 20px;
              font-family: Arial;
              font-weight: bold;
              color: #363636;
              opacity: 1;
            }
          }
          .lxp_support_diag_content_des {
            padding: 18px 0 40px;
            span {
              font-size: 16px;
              font-family: Arial;
              font-weight: 400;
              color: #595757;
              opacity: 1;
            }
          }
          .lxp_support_diag_content_btn {
            width: 23%;
            height: 43px;
            background: #2eb486;
            opacity: 1;
            position: relative;
            cursor: pointer;
            span {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 64px;
              height: 21px;
              font-size: 16px;
              font-family: Arial;
              font-weight: 400;
              line-height: 18px;
              color: #ffffff;
              opacity: 1;
            }
          }
        }
      }
    }
    .lxp_support_two_details_illustrate {
      width: 100%;
      .lxp_support_two_details_illustrate_flex {
        // display: flex;
        // justify-content: center;
        .lxp_support_two_details_illustrate_flex_1 {
          border: 1px solid #e4e4e4;
          width: 27%;
          display: flex;
          justify-content: space-around;
          flex-flow: row wrap;
          margin-left: 5%;
          margin-bottom: 5%;
          padding: 1%;
          float: left;
          .lxp_support_two_details_illustrate_flex_1_img {
            width: 35%;
            // height: 151px;
          }
          .lxp_support_two_details_illustrate_flex_1_content {
            width: 53%;
            .lxp_support_two_details_illustrate_flex_1_content_title {
              padding: 10% 0 2%;
              span {
                font-size: 20px;
                font-family: Arial;
                font-weight: bold;
                color: #363636;
                opacity: 1;
              }
            }
            .lxp_support_two_details_illustrate_flex_1_content_des {
              padding-bottom: 15%;
              span {
                font-size: 20px;
                font-family: Arial;
                font-weight: bold;
                color: #363636;
                opacity: 1;
              }
            }
            .lxp_support_two_details_illustrate_flex_1_content_btn {
              width: 94%;
              height: 43px;
              background: #2eb486;
              opacity: 1;
              position: relative;
              cursor: pointer;
              span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                font-size: 16px;
                font-family: Arial;
                font-weight: 400;
                color: #ffffff;
                opacity: 1;
              }
            }
          }
        }
        @media screen and (min-width: 1610px) {
          .lxp_support_two_details_illustrate_flex_1 {
            border: 1px solid #e4e4e4;
            width: 30%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_illustrate_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_illustrate_flex_1_content {
              width: 70%;
              .lxp_support_two_details_illustrate_flex_1_content_title {
                padding: 10% 0 2%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_btn {
                width: 94%;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
        @media (min-width: 1264px) and (max-width: 1609px) {
          .lxp_support_two_details_illustrate_flex_1 {
            border: 1px solid #e4e4e4;
            width: 30%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_illustrate_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_illustrate_flex_1_content {
              width: 70%;
              .lxp_support_two_details_illustrate_flex_1_content_title {
                padding: 10% 0 2%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_btn {
                width: 94%;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
        @media (min-width: 1264px) and (max-width: 1397px) {
          .lxp_support_two_details_illustrate_flex_1 {
            border: 1px solid #e4e4e4;
            width: 35%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_illustrate_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_illustrate_flex_1_content {
              width: 70%;
              .lxp_support_two_details_illustrate_flex_1_content_title {
                padding: 10% 0 2%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_btn {
                width: 94%;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
        @media screen and (max-width: 1263px) {
          .lxp_support_two_details_illustrate_flex_1 {
            border: 1px solid #e4e4e4;
            width: 40%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_illustrate_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_illustrate_flex_1_content {
              width: 70%;
              .lxp_support_two_details_illustrate_flex_1_content_title {
                padding: 10% 0 2%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_illustrate_flex_1_content_btn {
                width: 94%;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .lxp_support_upgrade {
      width: 100%;
      .lxp_support_two_details_upgrade_flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: row wrap;
        .lxp_support_two_details_upgrade_flex_1 {
          border: 1px solid #e4e4e4;
          width: 27%;
          display: flex;
          justify-content: space-around;
          flex-flow: row wrap;
          align-items: center;
          margin-left: 3%;
          margin-bottom: 5%;
          padding: 1%;
          // float: left;
          .lxp_support_two_details_illustrate_flex_1_img {
            width: 35%;
          }
          .lxp_support_two_details_illustrate_flex_1_content {
            width: 53%;
            .lxp_support_two_details_illustrate_flex_1_content_title {
              padding: 0% 0 15%;
              span {
                font-size: 16px;
                font-family: Arial;
                font-weight: 400;
                color: #363636;
                opacity: 1;
              }
            }
            .lxp_support_two_details_illustrate_flex_1_content_des {
              padding-bottom: 15%;
              span {
                font-size: 20px;
                font-family: Arial;
                font-weight: bold;
                color: #363636;
                opacity: 1;
              }
            }
            .lxp_support_two_details_illustrate_flex_1_content_btn {
              width: 94%;
              height: 43px;
              background: #2eb486;
              opacity: 1;
              position: relative;
              cursor: pointer;
              span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                font-size: 16px;
                font-family: Arial;
                font-weight: 400;
                color: #ffffff;
                opacity: 1;
              }
            }
          }
        }
        @media screen and (min-width: 1610px) {
          .lxp_support_two_details_upgrade_flex_1 {
            border: 1px solid #e4e4e4;
            width: 30%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_upgrade_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_upgrade_flex_1_content {
              width: 70%;
              .lxp_support_two_details_upgrade_flex_1_content_title {
                padding: 10% 0 5% 10%;
                text-align: left;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_btn {
                width: 80%;
                margin: auto;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
        @media (min-width: 1264px) and (max-width: 1609px) {
          .lxp_support_two_details_upgrade_flex_1 {
            border: 1px solid #e4e4e4;
            width: 30%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_upgrade_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_upgrade_flex_1_content {
              width: 70%;
              .lxp_support_two_details_upgrade_flex_1_content_title {
                padding: 10% 0 5% 10%;
                text-align: left;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_btn {
                width: 80%;
                margin: auto;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
        @media (min-width: 1264px) and (max-width: 1397px) {
          .lxp_support_two_details_upgrade_flex_1 {
            border: 1px solid #e4e4e4;
            width: 35%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_upgrade_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_upgrade_flex_1_content {
              width: 70%;
              .lxp_support_two_details_upgrade_flex_1_content_title {
                padding: 10% 0 5% 10%;
                text-align: left;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_btn {
                width: 80%;
                margin: auto;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
        @media screen and (max-width: 1263px) {
          .lxp_support_two_details_upgrade_flex_1 {
            border: 1px solid #e4e4e4;
            width: 40%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-flow: row wrap;
            margin-left: 5%;
            margin-bottom: 5%;
            padding: 1%;
            .lxp_support_two_details_upgrade_flex_1_img {
              width: 30%;
              // height: 151px;
            }
            .lxp_support_two_details_upgrade_flex_1_content {
              width: 70%;
              .lxp_support_two_details_upgrade_flex_1_content_title {
                padding: 10% 0 5% 10%;
                text-align: left;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_support_two_details_upgrade_flex_1_content_btn {
                width: 80%;
                margin: auto;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .lxp_support_margin_title {
      font-size: 20px;
      font-family: Arial;
      font-weight: bold;
      color: #363636;
      opacity: 1;
    }
  }
}
</style>