<template>
  <div class="lxp_searchresult min_height clear">
    <div class="lxp_searchresult_margin">
      <!-- <div class="lxp_searchresult_one">
                <span>搜索结果</span>
            </div>
            <div class="lxp_searchresult_two"></div> -->
      <div class="lxp_searchresult_three">
        <span>搜索结果：{{ searchVal }}</span>
      </div>

     


       <template v-if="url_data1.length > 0">
        <div
          v-for="(item, index) of url_data1"
          :key="`searchList + ${index}`"
          style="margin-bottom: 30px"
        >
          <!--产品-->
          <template v-if="item.type == 1">
            <div style="border-bottom: 1px solid rgba(235, 235, 235, 0.5);display:flex;justify-content: flex-start;flex-flow: row wrap;width:100%;margin-top:5%">
              <div class="lxp_searchresult_product_img" style="width:10%;" @click="toProduct(item.id)">
                <v-img :src="item.mpic"></v-img>
              </div>
              <div style="width:80%;margin-left:5%">
                <div class="lxp_searchresult_five" @click="toProduct(item.id)">
                  <span>{{ item.title }}</span>
                </div>
                <div class="lxp_searchresult_six" @click="toProduct(item.id)">
                  <span >{{item.subject}}</span>
                </div>
              </div>
            </div>
          </template>

          <!--支持-->
          <template v-else-if="item.type == 2">
            <!--常见问题-->
            <template v-if="item.cid == 1"> </template>

            <!--说明书-->
            <template v-else-if="item.cid == 2">
              <div class="lxp_searchresult_four">
                <div class="lxp_searchresult_two_details_illustrate">
                  <div class="lxp_searchresult_two_details_illustrate_flex">
                    <div class="lxp_searchresult_two_details_illustrate_flex_1" @click="toDownload(item.download)">
                      <div
                        class="
                          lxp_searchresult_two_details_illustrate_flex_1_img
                        "
                      >
                        <v-img contain :src="item.mpic"></v-img>
                      </div>
                      <div
                        class="
                          lxp_searchresult_two_details_illustrate_flex_1_content
                        "
                      >
                        <div
                          class="
                            lxp_searchresult_two_details_illustrate_flex_1_content_title
                          "
                        >
                          <span>{{ item.title }}</span>
                        </div>

                        <div
                          class="
                            lxp_searchresult_two_details_illustrate_flex_1_content_btn
                          "
                          @click="toDownload(item.download)"
                        >
                          <span>点击下载</span>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </template>

            <!--诊断知识-->
            <template v-else-if="item.cid == 3">

            </template>

            <!--升级中心-->
            <template v-else-if="item.cid == 4">
            </template>
          </template>

          <!--新闻-->
          <template v-else-if="item.type == 3">
            <div style="border-top: 1px solid rgba(235, 235, 235, 0.5)">
              <div class="lxp_searchresult_five" @click="toNews(item.id)">
                <span>{{ item.title }}</span>
              </div>
              <div class="lxp_searchresult_six" @click="toNews(item.id)">
                <span v-html="item.content"></span>
              </div>
            </div>
          </template>
        </div>
        <div class="lxp_searchresult_float_page">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="7"
            @input="goToPage"
            prev-icon="上一页"
      next-icon="下一页"
          ></v-pagination>
        </div>
      </template>

      <template v-else>
        <div class="lxp_searchresult_center_nodata">
          <h3>暂无数据，换个关键词试试</h3>
        </div>
      </template>
      <div class="lxp_searchresult_seven"></div>
    </div>
  </div>
</template>
<script>
import { getSearch} from "@/api/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      totalList:[],
      page: 1,
      searchList: [],
      searchList2: {},
      searchVal: "",
      pageLength: 0,
      resultList: [],
    };
  },
  created() {
    let _content = this.$route.query;
    console.log(_content);
  },
  mounted() {
    console.log(this.$route.query);
    // this.searchList = JSON.parse(this.$route.query._arr);
    // this.searchList2 = JSON.parse(this.$route.query._arr2);
    // if(this.searchList.length%10==0){
    //   this.pageLength = this.searchList.length/10;
    // }else{
    //   this.pageLength = this.searchList.length/10 + 1;
    // }
    // console.log(
    //   "==========打印类型",
    //   typeof this.searchList,
    //   typeof this.searchList2
    // );
    // this.searchList.map((item, index) => {
    //   this.resultList.push(item);
    // });

    // this.resultList = [...this.searchList,...this.searchList2];
    this.searchVal = this.$route.query.w;
    if(this.url_data1.length%10==0){
      this.pageLength = this.searchList.length/10;
    }else{
      this.pageLength = this.searchList.length/10 + 1;
    }
    
  },
  methods: {
    //跳转到产品页面
    toProduct(_id){
      this.$router.push("/product/" + _id);
    },
    //跳转到对应新闻页
    toNews(_id) {
      this.$router.push("/newsdetails/" + _id);
    },
    //下载说明书
    toDownload(_url) {
      window.open(_url);
    },
    goToPage() {
      // this.resultList = [];
      return getSearch({ w: this.searchVal, page: this.page, size: 10 }).then(
        (res) => {
          if (res && res.code == 0 && res.data) {
            console.log("=============",  res, res.data);
            //  this.searchList = res.data.data;
            // this.searchList2 = res.data.block;
            // this.totalList = [...this.searchList,...this.searchList2];
             sessionStorage.setItem("payload1",JSON.stringify(res.data.data));
        sessionStorage.setItem("payload2",JSON.stringify(res.data.block));
        this.$store.commit("resData1",res.data.data);
        this.$store.commit("resData2",res.data.block);


           

            // this.searchList.map((item, index) => {
            //   this.resultList.push(item);
            // });

           
            this.page = parseInt(res.data.current_page);
            this.pageLength = parseInt(res.data.last_page);
          }
        }
      );
    },
  },
  watch: {
    searchVal() {
      console.log(this.searchVal);
    },
    searchList() {
      console.log(this.searchList);
    },
    $route() {
      location.reload();
      // this.searchList = JSON.parse(this.$route.query._arr);
      // this.searchList2 = JSON.parse(this.$route.query._arr2);
      // this.searchList.map((item, index) => {
      //   this.resultList.push(item);
      // });

      // this.resultList = [...this.searchList,...this.searchList2];
      this.searchVal = this.$route.query.w;
      console.log("==========打印搜索结果", this.searchVal);
      // this.pageLength = this.searchList.length;
    },
  },
  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
      url_data1:(state) => state.urlData1,
      url_data2:(state) => state.urlData2,
    }),
  },
};
</script>
<style lang="scss" scoped>
.lxp_searchresult {
  width: 100%;
  .lxp_searchresult_margin {
    width: 60%;
    margin: 3% auto;
    
    // border: 1px solid;
    .lxp_searchresult_one {
    }
    .lxp_searchresult_two {
    }
    .lxp_searchresult_three {
      span {
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #000000;
        opacity: 1;
      }
    }
    .lxp_searchresult_four {
      padding: 2% 0 1%;
      .lxp_searchresult_two_details_illustrate {
        width: 100%;
        .lxp_searchresult_two_details_illustrate_flex {
          display: flex;
          justify-content: flex-start;
          flex-flow: row wrap;
          .lxp_searchresult_two_details_illustrate_flex_1 {
            cursor: pointer;
            border: 1px solid #e4e4e4;
            width: 35%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            margin: 1% 1% 1% 0;
            padding: 1%;
            .lxp_searchresult_two_details_illustrate_flex_1_img {
              width: 35%;
              // height: 151px;
              border: 1px solid;
            }
            .lxp_searchresult_two_details_illustrate_flex_1_content {
              width: 53%;
              .lxp_searchresult_two_details_illustrate_flex_1_content_title {
                padding: 10% 0 20%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_searchresult_two_details_illustrate_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_searchresult_two_details_illustrate_flex_1_content_btn {
                width: 94%;
                height: 43px;
                background: #2eb486;
                opacity: 1;
                position: relative;
                cursor: pointer;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
          @media (min-width: 1516px) and (max-width: 1742px) {
            .lxp_searchresult_two_details_illustrate_flex_1 {
              border: 1px solid #e4e4e4;
              width: 40%;
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-around;
              margin: 1% 1% 1% 0;
              padding: 1%;
            }
          }
          @media (min-width: 1342px) and (max-width: 1515px) {
            .lxp_searchresult_two_details_illustrate_flex_1 {
              border: 1px solid #e4e4e4;
              width: 45%;
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-around;
              margin: 1% 1% 1% 0;
              padding: 1%;
            }
          }
          @media (min-width: 1205px) and (max-width: 1341px) {
            .lxp_searchresult_two_details_illustrate_flex_1 {
              border: 1px solid #e4e4e4;
              width: 50%;
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-around;
              margin: 1% 1% 1% 0;
              padding: 1%;
            }
          }
          @media screen and (max-width: 1205px) {
            .lxp_searchresult_two_details_illustrate_flex_1 {
              border: 1px solid #e4e4e4;
              width: 60%;
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-around;
              margin: 1% 1% 1% 0;
              padding: 1%;
            }
          }
        }
      }
    }
    .lxp_searchresult_center_nodata {
      margin: 10% auto;
      transform: translateY(-50%);
      text-align: center;
    }
    .lxp_searchresult_five {
      cursor: pointer;
      padding-top: 1%;
      span {
        font-size: 20px;
        font-family: Arial;
        font-weight: bold;
        color: #363636;
        opacity: 1;
      }
    }
    .lxp_searchresult_six {
      cursor: pointer;
      padding: 1% 0 3%;
      span {
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #595757;
        opacity: 1;
      }
    }
    .lxp_searchresult_seven {
      width: 100%;
    }
  }
}
</style>