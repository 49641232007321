<template>
  <div class="lxp_diag min_height">
      <div style="width: 73%;margin:auto;">
    <div class="lxp_diag_one">
      <div class="lxp_diag_one_title">
        <span>{{ title }}</span>
      </div>
      <div class="lxp_diag_one_date">
        <span>{{ updated_at }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>编者：{{ author }}</span>
      </div>
    </div>
    <div class="lxp_diag_slider"></div>
    <div class="lxp_diag_two">
      <div class="lxp_diag_img">
        <v-img :src="mpic" contain></v-img>
      </div>
      <div class="lxp_diag_content">
        <span v-html="content"></span>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { getSupport, getSupportList, getSupportDetail } from "@/api/api";
export default {
  props: ["id"],
  data() {
    return {
      title: "",
      updated_at: "",
      author: "",
      mpic: "",
      content: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getDiagPage();
  },
  mounted() {
    let _arr = [1,2,3,4,5,6,7,8,9];
    console.log(_arr);
    let _result = _arr.reduce((prev,elem) => {
      return prev+elem;
    });
    console.log(_result);

    let _str = "helloworld";
    console.log(_str);
    let _arr1 = _str.split("");
    console.log(_arr1)
    let _obj = _arr1.reduce((prev,elem) => {
      console.log(prev,typeof(prev),elem,typeof(elem))
      if(prev[elem]===undefined){
        prev[elem] = 1;
      }else{
        prev[elem]++;
      }
      return prev;
    },{})
    console.log(_obj);
    console.log(this.id);
  },
  methods: {
    getDiagPage() {
      return getSupportDetail({ id: this.id }).then((res) => {
        console.log(res);
        if (res && res.data && res.code == 0) {
          this.title = res.data.title;
          this.updated_at = res.data.updated_at;
          this.author = res.data.author;
          this.mpic = res.data.mpic;
          this.content = res.data.content;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.lxp_diag {
  width: 100%;
  .lxp_diag_one {
    width: 100%;
    margin: 3% auto 0;
    .lxp_diag_one_title {
      text-align: center;
      span {
        font-size: 30px;
        font-family: Arial;
        font-weight: 700;
        color: #363636;
      }
    }
    .lxp_diag_one_date {
      text-align: center;
      padding: 1% 0 3%;
      span {
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #919191;
      }
    }
  }
  .lxp_diag_slider {
    width: 100%;
    height: 1px;
    margin:0 auto 2%;
    background-color: rgba(211, 211, 211, 0.39);
  }
  .lxp_diag_two {
    padding-bottom: 2%;
    width: 650px;
    margin: auto;
    .lxp_diag_img {
    }
    .lxp_diag_content {
    }
  }
}
</style>