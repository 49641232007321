<template>
  <div class="lxp_service min_height">
    <div style="width: 60%; margin: auto">
      <div style="text-align: center; font-size: 28px">{{ title }}</div>
      <div>
        <span v-html="content"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { getService } from "@/api/api";
export default {
  data() {
    return {
      content: "",
      title: "",
    };
  },
  created() {
    this.getServicePage();
  },
  methods: {
    getServicePage() {
      return getService().then((res) => {
        if (res && res.code == 0 && res.data) {
          this.content = res.data.main.content;
          this.title = res.data.main.title;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lxp_service {
}
</style>