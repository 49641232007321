<template>
  <div class="lxp_newsdetails min_height">
    <div class="lxp_newsdetails_margin">
      <div class="lxp_newsdetails_one">
        <span>{{title}} </span>
      </div>
      <div class="lxp_newsdetails_two">
        <span> {{updated_at}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;</span>
        <span>编者：{{author}}</span>
      </div>
      <div class="lxp_newsdetails_three"></div>
      <div class="lxp_newsdetails_four">
        <!-- <div class="lxp_newsdetails_four_img">
          <v-img :src="mpic" max-width="800px" max-height="400px" contain></v-img>
        </div> -->
        <div class="lxp_newsdetails_four_content">
          <span v-html="content">
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNewsDetails} from "@/api/api";
export default {
  props:["id"],
  data() {
    return {
      author:"",
      content:"",
      mpic:"",
      title:"",
      update_at:"",
    };
  },
    created(){
    this.loadNewsDetailsPage();
  },
  methods:{
      loadNewsDetailsPage(){
        console.log(this.page);
        return getNewsDetails({id:this.id})
        .then((res) => {
          if(res && res.data && res.code == 0){
            this.author = res.data.author;
            console.log(res.data,res.data.author,res.data.created_at)
            this.content = res.data.content;
            this.mpic = res.data.mpic;
            this.title = res.data.title;
            this.updated_at = res.data.updated_at;
          }
          console.log(res);
        })
      }
  },
};
</script>

<style lang="scss" scoped>
.lxp_newsdetails {
  width: 100%;
  background: #ffffff;
  .lxp_newsdetails_margin {
    width: 73%;
    margin: 3% auto 6%;
  }
  .lxp_newsdetails_one {
    width: 50%;
    margin: auto;
    text-align: center;
    span {
      font-size: 30px;
      font-family: Arial;
      font-weight: bold;
      color: #363636;
      opacity: 1;
    }
  }
  .lxp_newsdetails_two {
    width: 50%;
    margin: auto;
    text-align: center;
    padding: 1% 0 3%;
    span {
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #919191;
      opacity: 1;
    }
  }
  .lxp_newsdetails_three {
    width: 100%;
    height: 0px;
    border: 1px solid rgba(208,208,208,0.39);
    opacity: 1;
    margin-bottom: 3%;
  }
  .lxp_newsdetails_four {
    margin: auto;
    .lxp_newsdetails_four_img {
      width: 57.5%;
      // height: 400px;
      // border: 1px solid;
      margin: auto;
    }
    .lxp_newsdetails_four_content {
      width: 800px;
      margin: 2% auto 3%;
      line-height: 35px;
      span {
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #919191;
        opacity: 1;
      }
    }
  }
}
</style>