<template>
  <div class="lxp_about min_height">
    <div class="lxp_about_margin">
      <div class="lxp_about_content1 clear">
        <v-img :src="mainData.mpic"></v-img>
      </div>
      <div class="lxp_about_content2">
        <h4>{{ mainData.title }}</h4>
        <span v-html="mainData.content"></span>
        <!-- 
        <span
          >公司通过对行业和市场方向的把控，极致创新设计、积累研发，并结合市场需求推出HDT
          701 和 HDT 702X
          等多款旗舰级商用车诊断设备，在商用车诊断行业引起广泛关注。随后，公司又陆续推出汽车里程读取器等汽车专业检测工具，并赢得市场一致好评。公司持续不断的在商用车维修领域及汽车检测工具类产品投入研发，旨在向市场推出更多、更专业的产品。</span
        >

        <span
          >公司以“让天下没有难修的商用车”为使命，运用大数据分析，结合车联、网联等技术，通过云计算、线上线下相结合的智慧化解决方案，让全球用户，在第一时间掌握最新的汽车维修知识，并及时得到最佳修车解决方案，使修车之路不再有难题，新芯智能助力商用车维修之路更轻松。
        </span>
        <span
          >创新，每一次的探索，只为追求新的突破；拼搏，每一次的努力，只为点滴的进步；专注，每一次的用心，只为精益求精。</span
        > -->
      </div>
      <div class="lxp_about_content3">
        <div class="lxp_about_content3_1">
          <div class="lxp_about_content3_1_img">
            <v-img :src="mainData.picl"></v-img>
          </div>
          <div class="lxp_about_content3_1_w">
            <span v-html="mainData.content_l"></span>
            <!-- <p>以人为本，诚信经营，卓越品质，服务至上，共享共赢。</p>
            <span>
              以人为本,发挥个人价值，尊重人才引进，为企业提供可持续发展战略，为社会提供行业人才培养。
              诚信经营，企业在市场经济的一切活动中要遵纪守法、诚实守信、诚恳待人，以信取人，对他人给予信任，方能走得长远；不做无畏的口头承诺，只做付诸行动的结果目标。求真务实，真抓实干。
              卓越品质，公司注重产品研发，为用户提供最具竞争力的产品，质量可靠；产品是企业生存之根本，为市场输出优质可靠的产品，得到大家的认同，并赢得市场的认可；成为全球最受信奈的商用车诊断产品服务商。
              服务至上，倾注全部心血和精力全心全意为用户提供优质的产品服务与售后服务；全心全意为合作伙伴增效服务；全心全意为员工谋利服务；服务无止境，一点点的改变，只为更好的服务与您。
              共享共赢，为用户提供优质的产品与服务；为员工创造实现自我价值的平台；为客户创造良好的经济效益；为社会提供力所能及的公益资助；企业赢得社会的认可，做一家有社会责任心的企业；创新发展，共享共赢。
            </span> -->
          </div>
        </div>
        <div class="lxp_about_content3_2">
          <div class="lxp_about_content3_2_img">
            <v-img :src="mainData.picr"></v-img>
          </div>
          <div class="lxp_about_content3_2_w">
            <span v-html="mainData.content_r"></span>
            <!-- <p>使命，让天下没有难修的车</p>
            <span>
              整合行业资源，积累专业知识，专注产品研发，提供专业服务；用科技改变修车，让修车更智能，更简单，更省时，把复杂的事情全部交给新芯智能。打造商用车行业生态，助力商用车维修生态发展。让天下商用车维修工程师会修车，修好车。
            </span> -->
          </div>
        </div>
      </div>
      <div class="lxp_about_content4 clear">
        <div class="lxp_about_content4_1">
          <span>{{ mainTitle }}</span>
        </div>
        <div class="lxp_about_content4_2">
          <div
            class="lxp_about_content4_2_float"
            v-for="(item, index) of businessData"
            :key="`businessData + ${index}`"
          >
            <div class="lxp_about_content4_2_float_1">
              <v-img :src="item.mpic"></v-img>
            </div>
            <div class="lxp_about_content4_2_float_2">
              <p>{{ item.title }}</p>
            </div>
            <div class="lxp_about_content4_2_float_3">
              <span v-html="item.content"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="lxp_about_content5 clear">
        <div class="lxp_about_content5_1">
          <span>{{ jobTitle }}</span>
        </div>
        <div class="lxp_about_content5_2">
          <p>职位名称</p>
          <p>职位类别</p>
          <p>工作地点</p>
          <p>招聘人数</p>
          <p>更新时间</p>
        </div>
        <div
          class="lxp_about_content5_3"
          v-for="(i, j) of jobData"
          :key="`joinUs + ${j}`"
        >
          <div>
            <span>{{ i.name }}</span>
          </div>
          <div>
            <span>{{ i.kind }}</span>
          </div>
          <div>
            <span>{{ i.address }}</span>
          </div>
          <div>
            <span>{{ i.nums }}</span>
          </div>
          <div>
            <span>{{ i.updated_at }}</span>
          </div>
        </div>
        <div class="lxp_about_content5_4" @click="toMore">
          <span>查看更多</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getAbout, getJobs } from "@/api/api";
export default {
  data() {
    return {
      mainData: {},
      joinUs: [
        {
          rang: "国内销售",
          job: "销售",
          address: "深圳",
          number: "3",
          update: "2022-02-08",
        },
        {
          rang: "国内销售",
          job: "销售",
          address: "深圳",
          number: "3",
          update: "2022-02-08",
        },
        {
          rang: "国内销售",
          job: "销售",
          address: "深圳",
          number: "3",
          update: "2022-02-08",
        },
        {
          rang: "国内销售",
          job: "销售",
          address: "深圳",
          number: "3",
          update: "2022-02-08",
        },
        {
          rang: "国内销售",
          job: "销售",
          address: "深圳",
          number: "3",
          update: "2022-02-08",
        },
        {
          rang: "国内销售",
          job: "销售",
          address: "深圳",
          number: "3",
          update: "2022-02-08",
        },
      ],
      operations: [
        {
          img: this.$store.state.imgPath + "home/" + "ab04.png",
          title: "维修辅助工具",
          contents:
            "短路/开路测试仪、柱塞测试仪、传感器模拟器、CAN模块分析仪、里程表等实用小产品，方便快速处理汽修中的各种问题。",
        },
        {
          img: this.$store.state.imgPath + "home/" + "ab05.png",
          title: "独家诊断芯片",
          contents:
            "短路/开路测试仪、柱塞测试仪、传感器模拟器、CAN模块分析仪、里程表等实用小产品，方便快速处理汽修中的各种问题。",
        },
        {
          img: this.$store.state.imgPath + "home/" + "ab06.png",
          title: "商用车诊断工具",
          contents:
            "短路/开路测试仪、柱塞测试仪、传感器模拟器、CAN模块分析仪、里程表等实用小产品，方便快速处理汽修中的各种问题。",
        },
        {
          img: this.$store.state.imgPath + "home/" + "ab07.png",
          title: "测验设备",
          contents:
            "短路/开路测试仪、柱塞测试仪、传感器模拟器、CAN模块分析仪、里程表等实用小产品，方便快速处理汽修中的各种问题。",
        },
      ],
      businessData: [],
      jobData: [],
      mainTitle: "",
      jobTitle: "",
      totalSize: 0,
    };
  },
  created() {
    this.getAboutPage(), this.getJobsPage();
  },
  mounted() {},
  updated() {},
  methods: {
    getAboutPage() {
      return getAbout().then((res) => {
        if (res && res.code == 0 && res.data) {
          this.mainData = res.data.main;
          this.businessData =
            res.data.block.ABOUT_MAIN_BUSINESS_PAGE.blocklists; //
          this.mainTitle = res.data.block.ABOUT_MAIN_BUSINESS_PAGE.title;
          this.jobTitle = res.data.block.ABOUT_JOBS_PAGE.title;
          console.log(res.data, this.businessData, this.jobData);
        }
      });
    },

    //获取工作岗位接口
    getJobsPage() {
      return getJobs().then((res) => {
        if (res && res.code == 0 && res.data) {
          this.totalSize = res.data.total;
          console.log(this.jobData, this.totalSize);
          res.data.data.map((item, index) => {
            item.updated_at = item.updated_at.slice(0, 10);
          });
          this.jobData = res.data.data;
        }
      });
    },

    //查看更多
    toMore() {
      if (this.jobData.length < 6) {
        alert("亲，没有更多数据啦");
      } else {
        return getJobs({ page: this.totalSize, page: 1 }).then((res) => {
          if (res && res.code == 0 && res.data) {
            this.jobData = res.data.data;
            console.log("===显示全部");
          }
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
    }),
  },
};
</script>

<style lang="scss" scoped>
.lxp_about {
  width: 100%;
  background: #ffffff;
  .lxp_about_margin {
    width: 63%;
    margin: 3% auto 6%;
    .lxp_about_content1 {
    }
    .lxp_about_content2 {
      width: 95%;
      background: #007a61;
      opacity: 0.87;
      text-align: center;
      margin: -4.5% auto 0;
      z-index: 999;
      h4 {
        padding-top: 2%;
        font-size: 30px;
        font-family: Arial;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
      }
      span {
        padding: 2%;
        display: block;
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.92;
        text-align: left;
      }
    }
    .lxp_about_content3 {
      display: flex;
      justify-content: space-between;
      padding-top: 10%;
      .lxp_about_content3_1_w{
        margin-top: 5%;
      }
      .lxp_about_content3_2_w{
        margin-top: 5%;
      }
      .lxp_about_content3_1,
      .lxp_about_content3_2 {
        width: 42%;
        p {
          margin: 0;
          padding: 4% 0 1%;
          font-size: 16px;
          font-family: Arial;
          font-weight: bold;
          color: #363636;
          opacity: 1;
        }
        span {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #595757;
          opacity: 1;
        }
      }
    }
    .lxp_about_content4 {
      padding-top: 10%;
      .lxp_about_content4_1 {
        text-align: center;
        padding-bottom: 3%;
        span {
          font-size: 30px;
          font-family: Arial;
          font-weight: bold;
          color: #363636;
          opacity: 1;
        }
      }
      .lxp_about_content4_2 {
        .lxp_about_content4_2_float {
          float: left;
          width: 24%;
          height: 550px;
          padding: 2% 0;
          margin-right: 1%;
          background: #f6f6f6;
          p {
            font-size: 20px;
            font-family: Arial;
            font-weight: bold;
            color: #363636;
            opacity: 1;
          }
          span {
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #919191;
            opacity: 1;
          }
          .lxp_about_content4_2_float_1 {
            width: 51%;
            margin: auto;
            // height: 177px;
          }
          .lxp_about_content4_2_float_2 {
            text-align: center;
            margin-top: 6%;
          }
          .lxp_about_content4_2_float_3 {
            padding: 0 12px;
          }
        }
      }
    }
    .lxp_about_content5 {
      padding-top: 10%;
      .lxp_about_content5_1 {
        text-align: center;
        padding-bottom: 3%;
        span {
          font-size: 30px;
          font-family: Arial;
          font-weight: bold;
          color: #363636;
          opacity: 1;
        }
      }
      .lxp_about_content5_2 {
        background: #f6f6f6;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 60px;
        p {
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #363636;
          opacity: 1;
          margin: 0;
        }
      }
      .lxp_about_content5_3 {
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #eeeeee;
        align-items: center;
        height: 65px;
        div {
          // margin: 0 5%  0 8%;
          // width: 10%;
          // padding-left: 2%;
          span {
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #595757;
            opacity: 1;
          }
        }
        div:nth-child(4){
          padding-left: 5%;
        }
      }
      .lxp_about_content5_4 {
        cursor: pointer;
        margin: 3% auto 0;
        width: 14%;
        height: 43px;
        border: 1px solid #707070;
        opacity: 1;

        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #919191;
          opacity: 1;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>