<template>
  <div class="lxp_product min_height clear">
    <!--详情页上半部分-->
    <div class="lxp_product_one">
      <div class="lxp_product_one_margin">
        <div class="lxp_product_one_margin_flex">
          <div class="lxp_product_one_margin_flex_1">
            <div class="lxp_product_one_margin_flex_1_big">
              <v-carousel
                :cycle="false"
                hide-delimiters
                hide-delimiter-background
                v-model="lbSelect"
                @change="lbChange"
                :show-arrows="false"
                height="auto"
              >
                <v-carousel-item
                  v-for="(item, i) in pics"
                  :key="`smimgs + ${i}`"
                  :src="item"
                >
                  <!-- <v-img  width="100%" :src="item.img"></v-img> -->
                </v-carousel-item>
              </v-carousel>
            </div>
            <div class="lxp_product_one_margin_flex_1_small">
              <div
                class="
                  fill-height
                  lxp_product_one_margin_flex_1_small_fill-height
                "
                align="center"
                style="display: flex; justify-content: flex-start; width: 100%"
              >
                <template v-for="(item, i) in pics">
                  <div
                    :key="i"
                    style="margin: 6px; border: 1px solid rgba(112, 112, 112,0.39); width: 21.9%"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="hover ? 12 : 2"
                        :class="{ 'on-hover': hover || lbSelect == i }"
                        style="padding: 6px;"
                      >
                        <v-img
                          width="100%"
                          contain
                          :src="item"
                          @click="changeBig(i)"
                        >
                        </v-img>
                      </v-card>
                    </v-hover>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="lxp_product_one_margin_flex_2">
            <div class="lxp_product_one_margin_flex_2_title">
              <span>{{name}}</span>
            </div>
            <div class="lxp_product_one_margin_flex_2_describe">
              <span>{{subject}}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--详情页下半部分-->
    <div class="lxp_product_two">
      <!--导航-->
      <div class="lxp_product_two_nav">
        <div class="lxp_product_two_nav_width">
          <div
            class="lxp_product_two_nav_width_1"
            v-for="(item, i) of daoHang"
            :key="`daohang + ${i}`"
            @click="changeStyle(i)"
          >
            <span>{{ item }}</span>
          </div>
        </div>
      </div>

      <!--导航详情-->
      <div class="lxp_product_two_details clear">
        <div style="margin-top: 50px" class="clear">
          <!--产品特征-->
          <template v-if="vindex == 0">
            <div v-html="content" style="width: 65%;margin: auto;"></div>
          </template>

          <!--规格参数-->
          <template v-if="vindex == 1">
            <!-- <div
              class="lxp_product_two_details_guige"
              v-for="(item, index) of params"
              :key="`params + ${index}`" 
            > -->
              <!-- <div class="lxp_product_two_details_guige_1">
                <span>{{ item.title }}</span>
              </div> -->
              <div class="lxp_product_two_details_guige_2" style="width: 50%;margin: auto;">
                <span v-html="this.params"></span>
              </div>
            <!-- </div> -->
          </template>

          <!--产品视频-->
          <template v-if="vindex == 2">
            <div class="clear lxp_product_two_details_video">
              <div class="lxp_product_two_details_video_mar">
                <video
                  style="margin: auto"
                  :src="video"
                  controls="controls"
                  width="100%"
                  muted="muted"
                  autoplay="autoplay"
                >
                  
                </video>
              </div>
            </div>
          </template>

          <!--说明书下载-->
          <template v-if="vindex == 3">
            <div class="lxp_product_two_details_illustrate">
              <div class="lxp_product_two_details_illustrate_flex">
                <div
                  class="lxp_product_two_details_illustrate_flex_1"
                  v-for="(item, index) of helps"
                  :key="`helps + ${index}`"
                >
                  <div class="lxp_product_two_details_illustrate_flex_1_img">
                    <v-img
                      contain
                      :src="item.pics"
                      max-width="120px"
                    ></v-img>
                  </div>
                  <div
                    class="lxp_product_two_details_illustrate_flex_1_content"
                  >
                    <div
                      class="
                        lxp_product_two_details_illustrate_flex_1_content_title
                      "
                    >
                      <span>{{item.title}} </span>
                    </div>
                    <!-- <div
                      class="
                        lxp_product_two_details_illustrate_flex_1_content_des
                      "
                    >
                      <span>中文版产品说明书</span>
                    </div> -->
                    <div
                      class="
                        lxp_product_two_details_illustrate_flex_1_content_btn
                      "
                      @click="toPDF(item.download)"
                    >
                      <span>{{item.content}}</span>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </template>

          <!--包装清单-->
          <template v-if="vindex == 4">
            <div class="lxp_product_two_details_pack clear">
              <div class="lxp_product_two_details_pack_box clear">
                <div class="lxp_product_two_details_pack_box_mar clear">
                  <div
                    class="lxp_product_two_details_pack_box_mar_one"
                    v-for="(i,j) of manifasts"
                    :key="`manifasts + ${j}`"
                  >
                    <div class="lxp_product_two_details_pack_box_mar_one_img">
                      <v-img :src="i.pics" max-width="166px"></v-img>
                    </div>
                    <div class="lxp_product_two_details_pack_box_mar_one_name">
                      <span>{{i.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getDetail
} from "@/api/api";
export default {
  name: "Product",
  props: ["id"],
  data() {
    return {
      guige: [
        { name: "操作系统", size: "Android 10" },
        { name: "内存", size: "4G" },
        { name: "存储容量", size: "128G" },
        { name: "电池", size: "6300mAh/7.6V" },
        { name: "蓝牙", size: "蓝牙5.0" },
        { name: "显示屏", size: "10英寸" },
        { name: "网络连接", size: "Wi-Fi" },
        { name: "外观尺寸", size: "283.5×198.5×36 mm" },
        { name: "工作温度", size: "0℃～50℃" },
        { name: "存储温度", size: "-10℃～60℃" },
      ],
      vindex: 0,
      daoHang: ["产品特征", "规格参数", "产品视频", "说明书下载", "包装清单"],
      lbSelect: "",
      smimgs: [
        this.$store.state.imgPath + "home/" + "HDT701.png",
        this.$store.state.imgPath + "home/" + "HDT701.png",
        this.$store.state.imgPath + "home/" + "HDT701.png",
        this.$store.state.imgPath + "home/" + "HDT701.png",
      ],
      name:"",
      mpic:"",
      pics:[],
      content:"",
      params:"",
      subject:"",
      video:"",
      helps:[],
      manifasts:[],
      
    };
  },
  created(){
    this.loadPage();
  },
  methods: {
    //说明书下载
    toPDF(_url){
      window.open(_url);
    },
    //详情页接口调用
    loadPage(){
      return getDetail({lang:"cn",id:this.id})
      .then((res) => {
        if(res && res.code == 0 && res.data){
          this.content = res.data.content;
          this.name = res.data.name;
          this.mpic = res.data.mpic;
          this.pics = JSON.parse(res.data.pics);
          this.subject = res.data.subject;
          this.params = res.data.params[0].content;
          this.video = res.data.videos[0].video;
          this.helps = res.data.helps;
          let _arr1 = [];
          let _arr2 = [];
          res.data.manifasts.map((item,index) => {
            console.log(item.pics,typeof(item.pics))
            let _arr= JSON.parse(item.pics);
            console.log(_arr,typeof(_arr));
            let a = _arr.toString();
            console.log(a,typeof(a));
            item.pics = a;
            _arr1.push(item);
          })
          this.manifasts = _arr1;

          res.data.helps.map((item,index) => {
            console.log(item.pics,typeof(item.pics))
            let _arr3= JSON.parse(item.pics);
            console.log(_arr3,typeof(_arr));
            let b = _arr3.toString();
            console.log(b,typeof(b));
            item.pics = b;
            _arr2.push(item);
          })
          this.helps = _arr2;
      
        }
        console.log(res,"=============irutiweurio",res.data.params,);
      })
    },
    lbChange() {},
    changeBig(i) {
      this.lbSelect = i;
    },
    //改变导航样式
    changeStyle(i) {
      this.vindex = i;
      let _n = document.getElementsByClassName("lxp_product_two_nav_width_1");

      for (let j = 0; j < _n.length; j++) {
        console.log("I'm comming",this.helps);
        if (j == i) {
          _n[j].className += " ";
          _n[j].className += "lxp_product_nav_style_gray";
        } else {
          _n[j].classList.remove("lxp_product_nav_style_gray");
        }
      }
    },
  },
  mounted() {
    let _cc = document.getElementsByClassName("lxp_product_two_nav_width_1");
    _cc[0].className += " ";
    _cc[0].className += "lxp_product_nav_style_gray";
  },
  watch:{
    $route(){
      this.loadPage();
    },
    helps(){
      console.log(this.helps);
    }
  },
  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
    }),
  },
};
</script>
<style lang="scss" scoped>
.lxp_product {
  width: 100%;
  .lxp_product_one {
    background: #ffffff;
    width: 100%;
    .lxp_product_one_margin {
      width: 65%;
      margin: 3% auto 6%;
      .lxp_product_one_margin_flex {
        display: flex;
        justify-content: space-around;
        .lxp_product_one_margin_flex_1 {
          width: 30%;
          .lxp_product_one_margin_flex_1_big {
            border: 1px solid rgba(112, 112, 112,0.39);
            // height: 384px;
            text-align: center;
          }
          .lxp_product_one_margin_flex_1_small {
            // justify-content: flex-start;
            // width: 662px;
            // height: 108px;
            width: 100%;
            // height: 76px;
            .lxp_product_one_margin_flex_1_small_fill-height {
              width: 100%;
            }
            .v-card {
              transition: opacity 0.4s ease-in-out;
            }
            .v-card:not(.on-hover) {
              opacity: 0.6;
            }
          }
        }
        .lxp_product_one_margin_flex_2 {
          width: 60%;
          padding-top: 24px;
          .lxp_product_one_margin_flex_2_title {
            padding-bottom: 18px;
            span {
              font-size: 30px;
              font-family: Arial;
              font-weight: bold;
              color: #363636;
              opacity: 1;
            }
          }
          .lxp_product_one_margin_flex_2_describe {
            span {
              font-size: 16px;
              font-family: Arial;
              font-weight: 400;
              color: #919191;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .lxp_product_nav_style_gray {
    //       width: 137px;
    // height: 52px;
    background: #f5f5f5 !important;
    opacity: 1 !important;
  }
  .lxp_product_cc_spanSty {
    margin: 10px auto !important;
    display: inline-block;
  }
  .lxp_product_two {
    width: 100%;
    .lxp_product_two_nav {
      width: 100%;
      background: #ffffff;
      .lxp_product_two_nav_width {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 18%;
        .lxp_product_two_nav_width_1 {
          cursor: pointer;
          width: 20%;
          text-align: center;
          span {
            font-size: 20px;
            font-family: Arial;
            font-weight: bold;
            color: #363636;
            opacity: 1;
            margin: 10px auto !important;
            display: inline-block;
          }
        }
      }
    }
    .lxp_product_two_details {
      width: 100%;
      padding-bottom: 50px;
      background: #f5f5f5;
      .lxp_product_two_details_guige {
        width: 40%;
        margin: auto;
        border-bottom: 1.5px solid #cccccc;
        display: flex;
        justify-content: space-around;
        .lxp_product_two_details_guige_1 {
          width: 30%;
          padding: 1%;
        }
        .lxp_product_two_details_guige_2 {
          width: 45%;
          padding: 1%;
        }
      }
      .lxp_product_two_details_video {
        width: 100%;
        margin: auto;
        .lxp_product_two_details_video_mar {
          width: 63%;
          margin: auto;
        }
      }
      .lxp_product_two_details_illustrate {
        width: 100%;
        .lxp_product_two_details_illustrate_flex {
          display: flex;
          justify-content: center;
          .lxp_product_two_details_illustrate_flex_1 {
            border: 1px solid #e4e4e4;
            width: 20%;
            display: flex;
            justify-content: space-around;
            margin: 0 2%;
            padding: 1%;
            .lxp_product_two_details_illustrate_flex_1_img {
              width: 35%;
              // height: 151px;
              // border: 1px solid;
            }
            .lxp_product_two_details_illustrate_flex_1_content {
              width: 53%;
              .lxp_product_two_details_illustrate_flex_1_content_title {
                padding: 10% 0 2%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_product_two_details_illustrate_flex_1_content_des {
                padding-bottom: 15%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
              .lxp_product_two_details_illustrate_flex_1_content_btn {
                width: 94%;
                height: 43px;
                background: #2EB486;
                opacity: 1;
                position: relative;
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  font-size: 16px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #ffffff;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .lxp_product_two_details_pack {
        width: 100%;
        .lxp_product_two_details_pack_box {
          margin: auto;
          width: 63%;
          background: rgba(255, 255, 255, 0.39);
          border: 1px solid rgba(112, 112, 112,0.39);
          opacity: 1;
          .lxp_product_two_details_pack_box_mar {
            width: 92%;
            margin: 3% 3%;
      
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row wrap;
            .lxp_product_two_details_pack_box_mar_one {
              width: 30%;
              margin: 1%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              .lxp_product_two_details_pack_box_mar_one_img {
                width: 48%;
              }
              .lxp_product_two_details_pack_box_mar_one_name {
                width: 48%;
                span {
                  font-size: 20px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #363636;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>