<template>
  <div class="lxp_contact clear min_height">
    <div class="lxp_contact_flex">
      <div class="lxp_contact_flex_1">
        <v-img :src="mpic"></v-img>
      </div>
      <div class="lxp_contact_flex_2">
        <div class="lxp_contact_flex_2_title">
          <span>{{title}}</span>
        </div>
        <div class="lxp_contact_flex_2_tel">
          <p v-html="address">
            <!-- 地 址: 深圳市龙岗区坂田街道清湖工业区宝能科技园(南区)一期 12栋 806 -->
          </p>
          <!-- <p>邮 箱: service@inewchip.com</p>
          <p>电 话: 0755-21036827</p> -->
        </div>
        <div >
          <iframe
                  class="lxp_about_contents_two_address_map"
                  src="https://j.map.baidu.com/e5/hD9J"
                  frameborder="0"
                  width="70%"
                  height="350px"
                ></iframe>
        </div>
        <div class="lxp_contact_flex_2_notes">
          <div class="lxp_contact_flex_2_notes_1">
            <span>{{liuyan.title}}</span>
          </div>
          <div class="lxp_contact_flex_2_notes_2">
            <input type="text" :placeholder="liuyan.name" v-model="names" />
            <input
              type="text"
              :placeholder="liuyan.email"
              v-model="email"
              style="margin-left: 3%"
            />
          </div>
          <div class="lxp_contact_flex_2_notes_3 clear">
            <textarea
              class="lxp_contact_flex_2_notes_3_textarea"
              name="name_of_textarea"
              id="textarea1"
              cols="30"
              rows="10"
              :placeholder="liuyan.discript"
              required
              v-model="tt"
            ></textarea>
          </div>

          <!--验证码-->
         <div class="lxp_contact_flex_2_notes_4">
            <input type="text" :placeholder="liuyan.vcode" v-model="yzm" />
            <div
              class="lxp_contact_flex_2_notes_4_txm"
              style="width: 17%; height: 40px; cursor: pointer; margin-left: 2%"
              @click="getVerifydataPage"
            >
            <v-img :src="url"></v-img>
              <!-- <canvas
                id="canvas"
                style="margin-left: 8%; width: 100%; height: 40px"
                @click="getVerification"
              ></canvas> -->
            </div>
          </div>
          <div class="lxp_contact_flex_2_notes_5 clear">
            <v-btn
              class="lxp_home_box_four_margin_three_btn"
              @click="getContactdataPage"
              >{{liuyan.send}}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getContact,
  getVerifydata,
  getContactdata,
} from "@/api/api";
export default {
  name: "Contact",
  data() {
    return {
      csrf:"",
      url:"",
      title:"",
      mpic:"",
      address:"",
      liuyan:"",
      mapPic:"",
        _arrStr:"",
        tt:"",
      characters: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "P",
        "O",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      names: "",
      email: "",
      desceibes: "",
      yzm: "",
    };
  },
  created() {
    this.getContactPage();
    this.getVerifydataPage();
    },

     mounted() {
    
    // this.getVerification();
    // let geoloc = window.navigator.geolocation;
    // geoloc.getCurrentPosition(
    //   (result) => {
    //     let lat = result.coords.latitude; //纬度
    //     let lng = result.coords.longitude; //经度
    //     let map = new AMap.Map("container1", {
    //       zoom: 16, //级别
    //       center: [114.064444, 22.671094], //中心点坐标
    //       viewMode: "3D", //使用3D视图
    //     });
    //     let marker = new AMap.Marker({
    //       position: [114.064444, 22.671094],
    //     });
    //     map.add(marker);
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  },
  methods: {
    //获取验证码数据接口
     getVerifydataPage(){
      return getVerifydata()
      .then((res) => {
        console.log(res);
        if(res && res.data && res.code == 0){
          console.log(res.data);
          this.csrf = res.data.csrf;
          this.url = res.data.url;
     
        }
      })
    },
    //获取数据发送接口
     getContactdataPage(){
      return getContactdata({
        name:this.names,
        email:this.email,
        content:this.tt,
        vcode:this.yzm,
        csrf:this.csrf
      })
      .then((res) => {
        console.log(res);
        if(res && res.data && res.code == 0){
          console.log(res.data);
     
        }
      })
    },
    //获取联系我们页接口数据
    getContactPage(){
      return getContact()
      .then((res) => {
        console.log(res);
        if(res && res.data && res.code == 0){
          console.log(res.data);
          this.title = res.data.title;
          this.address = res.data.content;
          let _str = JSON.parse(res.data.subject)
          this.liuyan = _str;
          this.mapPic = res.data.content_l;//百度地图https://j.map.baidu.com/e5/hD9J
          this.mpic = res.data.mpic;
          console.log("==========================留言板块",this.mpic);
        }
      })
    },

    //获取验证码数据接口

    //发送
    sendMessage() {
        this._arrStr = this._arrStr.toLowerCase();
        this.yzm = this.yzm.toLowerCase();
        let _flag = false;
        if(this._arrStr == this.yzm){
            _flag = true;
        }else{
          _flag = false;
        }
        let _text = document.getElementById("textarea1");
      let _reg1 = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      let _reg2 = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      let _result1 = _reg1.test(this.names);
      let _result2 = _reg2.test(this.email);
      if (!_result1) {
        alert("请输入正确的昵称");
      } else if (!_result2) {
        alert("请输入正确的邮箱");
      } else if(!this.tt){
        alert("请描述内容");
      }
      else if (!_flag) {
        alert("验证码不正确");
      } else if (_result1 && _result2 && _flag) {
        alert("提交成功");
      }
      
    },
    changeY(n) {
      return parseInt(Math.random() * n);
    },
    randomColor() {
      var colorValue = "0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f";
      var colorArray = colorValue.split(",");
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += colorArray[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getRandomNum(characters, count) {
      
      let shuffled = characters.slice(0);
    
      let i = characters.length;
      let min = i - count;
      let temp;
      let index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },
    getVerification() { 
      let ctx = document.getElementById("canvas").getContext("2d");
      // 清空画布
      ctx.clearRect(0, 0, 400, 400);
      // 设置字体
      ctx.font = "128px bold 黑体";
      // 设置垂直对齐方式
      ctx.textBaseline = "top";
      // 设置颜色
      ctx.fillStyle = this.randomColor();
      // 绘制文字（参数：要写的字，x坐标，y坐标）
      let _res1;
      let _res2;
      let _res3;
      let _res4;
      ctx.fillText(_res1 = this.getRandomNum(this.characters, 1), 0, this.changeY(50));
      
      ctx.fillStyle = this.randomColor();
      ctx.fillText(_res2 = this.getRandomNum(this.characters, 1), 50, this.changeY(50));
      ctx.fillStyle = this.randomColor();
      ctx.fillText(
        _res3 = this.getRandomNum(this.characters, 1),
        100,
        this.changeY(50)
      );
      ctx.fillStyle = this.randomColor();
      ctx.fillText(
        _res4 = this.getRandomNum(this.characters, 1),
        150,
        this.changeY(50)
      );
      let _arrResult = [..._res1,..._res2,..._res3,..._res4];
      let _str = _arrResult.join("");
      this._arrStr = _str;
    },
  },

 
  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
    }),
  },
};
</script>

<style lang="scss" scoped>
.lxp_contact {
  width: 100%;
  background: #ffffff;
  
  .lxp_contact_flex {
    display: flex;
    justify-content: flex-start;
    // margin-top: 3%;
    .lxp_contact_flex_1 {
      width: 36%;
      
    }
    .lxp_contact_flex_2 {
      width: 65%;
      // margin-top: 1.5%;
      padding-left: 3.5%;
     
      .lxp_contact_flex_2_title {
        margin-bottom: 12px;
        span {
          font-size: 30px;
          font-family: Arial;
          font-weight: bold;
          color: #363636;
          opacity: 1;
        }
      }
      .lxp_contact_flex_2_tel {
        margin-bottom: 12px;
        p {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #363636;
          opacity: 1;
        }
      }
      #container1 {
        width: 70%;
        height: 265px;
        margin-bottom: 48px;
      }
      .lxp_contact_flex_2_notes {
        width: 70%;
        // height: 350px;
      
        .lxp_contact_flex_2_notes_1 {
          margin-bottom: 24px;
          span {
            font-size: 30px;
            font-family: Arial;
            font-weight: bold;
            color: #363636;
            opacity: 1;
          }
        }
        .lxp_contact_flex_2_notes_2 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 16px;
          input {
            width: 34%;
            height: 43px;
            border: 1px solid #707070;
            opacity: 1;
            color: #919191;
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            padding-left: 12px;
            padding-right: 12px;
            outline: none;
          }
        }
        .lxp_contact_flex_2_notes_3 {
          margin-bottom: 16px;
          .lxp_contact_flex_2_notes_3_textarea {
            width: 71%;
            height: 150px;
            border: 1px solid #707070;
            padding-left: 12px;
            padding-top: 12px;
            outline: none;
          }
        }
        .lxp_contact_flex_2_notes_4 {
          display: flex;
          align-items: center;
          margin-bottom: 18px;

          input {
            width: 21.5%;
            height: 43px;
            border: 1px solid #707070;
            opacity: 1;
            color: #919191;
            font-size: 20px;
            font-family: Arial;
            font-weight: 400;
            padding-left: 12px;
            padding-right: 12px;
            outline: none;
          }
          .lxp_contact_flex_2_notes_4_txm {
            border: 1px solid rgba(211, 235, 235, 0.8);
          }
        }
        .lxp_contact_flex_2_notes_5 {
          margin-bottom: 12px;
          .lxp_home_box_four_margin_three_btn {
            cursor: pointer;
            width: 21.5%;
            height: 43px;
            background: #2eb486;
            opacity: 1;
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            line-height: 18px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>