import { render, staticRenderFns } from "./demo.vue?vue&type=template&id=a4c2d766&scoped=true&"
var script = {}
import style0 from "./demo.vue?vue&type=style&index=0&id=a4c2d766&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4c2d766",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VImg,VPagination})
